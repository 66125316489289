import { Controller } from 'stimulus'

const disableLinks = function (el) {
  Array.from(el.getElementsByTagName('a')).forEach(function (link) {
    link.href = ''
  })
}

export default class extends Controller {
  connect () {
    disableLinks(document.getElementById('main'))
    Array.from(document.getElementsByTagName('nav')).forEach(function (n) {
      disableLinks(n)
    })
  }

}