// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import Carousel from "stimulus-carousel"
import TextareaAutogrow from "stimulus-textarea-autogrow"


const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

application.register("carousel", Carousel)
application.register("textarea-autogrow", TextareaAutogrow)
