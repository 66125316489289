import { Controller } from 'stimulus'
import EasyMDE from 'easymde'

export default class extends Controller {
  connect () {
    this.easymde = new EasyMDE({
        element: this.element,
        showIcons: ['code', 'redo', 'heading', 'undo', 'heading-bigger', 'heading-smaller', 'heading-1', 'heading-2', 'heading-3', 'clean-block', 'horizontal-rule'],
        spellChecker: false,
        blockStyles: { code: '~~~'},
        promptURLs: true,
        initialValue: this.element.value
    });
  }

  disconnect () {
    this.easymde.toTextArea()
    this.easymde.cleanup()
    this.easymde = null
  }

  // get defaultOptions () {
  //   return {}
  // }
}